
import { authUser, logInPassword } from '@/api/authModule';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseInput from '@/components/baseComponents/BaseInput.vue';
import BaseInputPassword from '@/components/baseComponents/BaseInputPassword.vue';
import BaseCertificates from '@/components/baseComponents/BaseCertificates.vue';
import GoogleAuthComponent from '@/components/landingAndRegistrationComponents/GoogleAuthComponent.vue';
import TimerRedirect from '@/components/landingAndRegistrationComponents/TimerRedirect.vue';
import { sendEmailWithAuthLink } from '@/api/email';
import { enterToTheFlow, getNextPage } from '@/utils/redirectUtils';
import { getUserSettings } from '@/utils/updateInfo';
import { universalRegExpChecker } from '@/utils/validationUtils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import { getCompletedFlows, setCurrentStage, startFlow } from '@/api/flowSession';
// import { loadHubSpotChat } from '@/hubSpotChat';

@Component({
  components: {
    BaseInput,
    BaseInputPassword,
    BaseButton,
    BaseCertificates,
    GoogleAuthComponent,
    TimerRedirect,
  },
  methods: {
    ...mapMutations({
      setUserIdAndAccountId: 'user/setUserIdAndAccountId',
      rewriteUserDataFromResponse: 'user/rewriteUserDataFromResponse',
      rewriteLendioDataFromResponse: 'lendio/rewriteLendioDataFromResponse',
      setFlowVersion: 'tracking/setFlowVersion',
    }),
  },
  computed: {
    ...mapGetters('flowStages', ['getCurrentFlow']),
  },
})
export default class LoginPage extends Vue {
  public setUserIdAndAccountId!: (n: any) => void;
  public rewriteUserDataFromResponse!: (n: any) => void;
  public rewriteLendioDataFromResponse!: (n: any) => void;
  public setFlowVersion!: (n: any) => void;
  public getCurrentFlow!: string;

  loginStage = 'email';
  password = '';
  isPreloaderShow = false;
  errorMessageEmail = '';
  errorMessagePassword = '';
  wasError = false;
  errors = {
    email: false,
    password: false,
  };
  isEmailValid = false;
  timeout: null | number = null;
  isTimerRedirectShow = false;
  authStatus = '';

  get email(): string {
    return this.$store.state.user.email;
  }
  set email(value: string) {
    this.$store.commit('user/setEmail', value);
}
  get description(): string {
    return this.loginStage === 'goHome'
      ? "We have sent a link to create your password to your email address. If you didn't receive the email, please check your “spam” folder."
      : 'Glad to see you again';
  }
  get correctFields(): boolean {
    if (this.loginStage === 'email') {
      return this.isEmailValid;
    }
    if (this.loginStage === 'password') return this.password.length > 0;
    return true;
  }
  get buttonText(): string {
    return this.loginStage === 'goHome' ? 'Go home' : 'Login';
  }

  checkField(): void {
    this.errorMessageEmail = 'Please type your email correctly';
    this.errors.email = !universalRegExpChecker('email', this.email);
    this.errors.password = false;

    if (this.isEmailValid) this.checkEmailStatus();
  }
  async checkEmailStatus(): Promise<void> {
    this.authStatus = await authUser(this.email);
    this.isTimerRedirectShow = false;

    if (this.authStatus === 'NewUser') {
      this.errorMessageEmail = 'No user found, please check your email address or create a new account';
      this.errors.email = true;

      this.isTimerRedirectShow = true;
    }
  }
  onMountAndTypingValidation(fieldName: string): void {
    if (fieldName === 'email') this.isEmailValid = universalRegExpChecker('email', this.email);
  }
  nextButtonAction(): void {
    switch (this.loginStage) {
      case 'email':
        this.emailStageActions();
        break;
      case 'password':
        this.passwordStageActions();
        break;
      case 'goHome':
        this.goHomeStageActions();
        break;
      default:
        break;
    }
  }
  async emailStageActions(): Promise<void> {
    try {
      this.isPreloaderShow = true;
      await this.checkEmailStatus();

      switch (this.authStatus) {
        case 'CompleteUser':
          this.loginStage = 'password';
          break;
        case 'IncompleteUser':
          this.isPreloaderShow = true;
          await sendEmailWithAuthLink(this.email, 'create');
          this.loginStage = 'goHome';
          break;
        default:
          break;
      }
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isPreloaderShow = false;
    }
  }
  async passwordStageActions(): Promise<void> {
    try {
      this.isPreloaderShow = true;
      await logInPassword({
        email: this.email,
        password: this.password,
      });
      await getUserSettings();
      await enterToTheFlow();

      const currentFlow = this.$store.getters['flowStages/getCurrentFlow'];  
    
      if (currentFlow) {
        await startFlow(currentFlow);
        await setCurrentStage(getNextPage());
        this.$router.push({ name: getNextPage() });
      } 
       else {
        this.$router.push({ name: getNextPage() });
      }

      const { data: completedFlows } = await getCompletedFlows();
      this.$store.commit('flowStages/setCompletedFlowsArr', completedFlows)
      // await loadHubSpotChat();
    } catch (error: any) {
      if (error.response?.status === 401) {
        this.wasError = true;
      }
      this.errorMessagePassword = error.response?.data.message;
      this.errors.password = true;
    } finally {
      this.isPreloaderShow = false;
      this.clearErrorStatus();
    }
  }
  clearErrorStatus(): void {
    setTimeout(() => {
      this.wasError = false;
    }, 500);
  }
  goHomeStageActions(): void {
    this.$router.push({ name: 'HomePage' });
  }
  popupError(error: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: error,
    });
  }

  mounted(): void {
    if (this.email) {
      this.onMountAndTypingValidation('email');
    }
  }
  beforeDestroy(): void {
    if (this.timeout) clearTimeout(this.timeout);
  }

  @Watch('email')
  emailWatcher(): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.onMountAndTypingValidation('email');
    }, 500);
  }
}
